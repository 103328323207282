import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-movimento-estoque',
  templateUrl: './movimento-estoque.component.html'
})
export class MovimentoEstoqueComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

import { Injectable } from "@angular/core";
import { Contrato } from "../models/contrato";

@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  async consultarContratos(): Promise<Contrato[]> {
    return [
      { codigo: '00700CV01001', codigoTouro: '00700CV', nomeTouro: 'Nome do touro 1', tipoSemen: 'Convencional', destino: 'INDUSTRIALIZADO', palheta: 'Fina', dataInicio: new Date(), dataFim: new Date(), quantidadeContrato: 31000, quantidadeProduzida: 71000, emAberto: false },
      { codigo: '00701CV01001', codigoTouro: '00701CV', nomeTouro: 'Nome do touro 2', tipoSemen: 'Convencional', destino: 'INDUSTRIALIZADO', palheta: 'Fina', dataInicio: new Date(), dataFim: new Date(), quantidadeContrato: 31000, quantidadeProduzida: 71000, emAberto: true },
      { codigo: '00702CV01001', codigoTouro: '00702CV', nomeTouro: 'Nome do touro 3', tipoSemen: 'Convencional', destino: 'INDUSTRIALIZADO', palheta: 'Fina', dataInicio: new Date(), dataFim: new Date(), quantidadeContrato: 31000, quantidadeProduzida: 71000, emAberto: false },
      { codigo: '00703CV01001', codigoTouro: '00703CV', nomeTouro: 'Nome do touro 4', tipoSemen: 'Convencional', destino: 'INDUSTRIALIZADO', palheta: 'Fina', dataInicio: new Date(), dataFim: new Date(), quantidadeContrato: 31000, quantidadeProduzida: 71000, emAberto: false },
      { codigo: '00704CV01001', codigoTouro: '00704CV', nomeTouro: 'Nome do touro 5', tipoSemen: 'Convencional', destino: 'INDUSTRIALIZADO', palheta: 'Fina', dataInicio: new Date(), dataFim: new Date(), quantidadeContrato: 31000, quantidadeProduzida: 71000, emAberto: false },
      { codigo: '00705CV01001', codigoTouro: '00705CV', nomeTouro: 'Nome do touro 6', tipoSemen: 'Convencional', destino: 'INDUSTRIALIZADO', palheta: 'Fina', dataInicio: new Date(), dataFim: new Date(), quantidadeContrato: 31000, quantidadeProduzida: 71000, emAberto: true },
    ];
  }
}

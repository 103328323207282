import { Injectable } from "@angular/core";
import { Touro } from "../models/touro";

@Injectable({
  providedIn: 'root'
})
export class TourosService {
  async consultarTouros(): Promise<Touro[]> {
    return [
      { codigo: '01274AN', nome: 'Touro 1', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: false },
      { codigo: '01274AN', nome: 'Touro 2', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: false },
      { codigo: '01274AN', nome: 'Touro 3', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: true },
      { codigo: '01274AN', nome: 'Touro 4', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: true },
      { codigo: '01274AN', nome: 'Touro 5', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: false },
      { codigo: '01274AN', nome: 'Touro 6', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: false },
      { codigo: '01274AN', nome: 'Touro 7', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: false },
      { codigo: '01274AN', nome: 'Touro 8', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: false },
      { codigo: '01274AN', nome: 'Touro 9', dataEntrada: new Date(), dataSaida: new Date(), fimQuarentena: new Date(), inicioQuarentena: new Date(), pesoEntrada: 912, prevEntradaColeta: new Date(), central: true },
    ]
  }
}

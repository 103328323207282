import { Routes } from "@angular/router";
import { GestaoContratosComponent } from "./gestao-contratos/gestao-contratos.component";
import { HomeComponent } from "./home/home.component";
import { MeusTourosComponent } from "./meus-touros/meus-touros.component";
import { MonitorProducaoComponent } from "./monitor-producao/monitor-producao.component";
import { MovimentoEstoqueComponent } from "./movimento-estoque/movimento-estoque.component";

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'meus-touros', component: MeusTourosComponent },
  { path: 'gestao-contratos', component: GestaoContratosComponent },
  { path: 'monitor-producao', component: MonitorProducaoComponent },
  { path: 'movimento-estoque', component: MovimentoEstoqueComponent }
];

import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { Touro } from "src/app/core/models/touro";
import { TourosService } from "src/app/core/services/touros.service";

@Component({
  standalone: true,
  selector: 'app-touros',
  imports: [CommonModule, FormsModule, IonicModule],
  templateUrl: './meus-touros.component.html'
})
export class MeusTourosComponent implements OnInit {
  touros: Touro[] = [];
  tourosFiltro: Touro[] = [];
  nomeTouro: string = '';
  apenasCentral: boolean = false;

  constructor(private tourosService: TourosService) { }

  async ngOnInit() {
    this.touros = await this.tourosService.consultarTouros();
    this.tourosFiltro = this.touros;
  }

  filtrarTouros() {
    this.tourosFiltro = this.touros.filter(x => x.nome.toUpperCase().includes(this.nomeTouro.toUpperCase()))

    if (this.apenasCentral)
      this.tourosFiltro = this.tourosFiltro.filter(x => x.central);
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-monitor-producao',
  templateUrl: './monitor-producao.component.html'
})
export class MonitorProducaoComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

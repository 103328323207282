import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Contrato } from 'src/app/core/models/contrato';
import { ContratosService } from 'src/app/core/services/contratos.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-gestao-contratos',
  templateUrl: './gestao-contratos.component.html'
})
export class GestaoContratosComponent implements OnInit {
  contratos: Contrato[] = [];
  contratosFiltro: Contrato[] = [];
  nomeTouro: string = '';
  apenasContratoAbertos: boolean = false;

  constructor(private tourosService: ContratosService) { }

  async ngOnInit() {
    this.contratos = await this.tourosService.consultarContratos();
    this.contratosFiltro = this.contratos;
  }

  filtrarTouros() {
    this.contratosFiltro = this.contratos.filter(x => x.nomeTouro.toUpperCase().includes(this.nomeTouro.toUpperCase()))

    if (this.apenasContratoAbertos)
      this.contratosFiltro = this.contratosFiltro.filter(x => x.emAberto);
  }
}
